import { mapGetters } from "vuex";

export default {
  name: "quiz",
  data() {
    return {
      answers: [],
    };
  },
  computed: {
    ...mapGetters({
      quizInfo: "courses/quizInfo",
      quizResultInfo: "courses/quizResultInfo",
    }),
  },
  created() {
    if (!this.quizResultInfo) {
      this.goToProfile();
    }
  },
  methods: {
    goToProfile() {
      this.$router.push({ name: "platform" });
    },
  },
};
